import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import SelectFundingSource from 'components/funding-sources/SelectFundingSource';

export default function FormSelectFundingSource(props) {
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { id, name, label, required, size, fullWidth, disabled, variant } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <SelectFundingSource
            id={id}
            name={name}
            label={label}
            error={!!errors[name]}
            helperText={errors[name]?.message ?? ''}
            required={required}
            size={size}
            fullWidth={fullWidth}
            disabled={disabled}
            variant={variant}
            onSelected={(value) => {
              setValue(name, value);
              clearErrors(name);
            }}
            value={getValues(name)}
          />
        );
      }}
    />
  );
}

FormSelectFundingSource.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'large']),
  fullWidth: PropTypes.bool.isRequired,
  multiline: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  value: PropTypes.string,
};

FormSelectFundingSource.defaultProps = {
  id: 'select-funding-source',
  label: 'Select a funding source',
  required: false,
  disabled: false,
  fullWidth: true,
  multiline: false,
  size: 'small',
  variant: 'outlined',
};
