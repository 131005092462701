import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getWebhooksInit,
  getWebhooksSuccess,
  getWebhooksFailure,
  addWebhookInit,
  addWebhookSuccess,
  addWebhookFailure,
  updateWebhookInit,
  updateWebhookSuccess,
  updateWebhookFailure,
  getWebhookInit,
  getWebhookFailure,
  getWebhookSuccess,
  editWebhookInit,
  editWebhookSuccess,
  editWebhookFailure,
  deleteWebhookInit,
  deleteWebhookFailure,
  deleteWebhookSuccess,
} from './webhookSlice';

const getWebhook = createAction('webhooks/get');
const createWebhook = createAction('webhooks/create');
const updateWebhook = createAction('webhooks/update');
const getWebhooks = createAction('webhooks/list');
const editWebhook = createAction('webhooks/edit');
const deleteWebhook = createAction('webhooks/delete');

/**
 * create a webhook
 *
 * @param {object} data
 */
function* createWebhookSaga({ payload: data }) {
  yield put(addWebhookInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createWebhook, data));
    yield put(addWebhookSuccess(response.data));
    Notify.success('New Webhook created successfully.');
  } catch (err) {
    yield put(addWebhookFailure(err));
    Notify.error('Failed to create new webhook.');
  }
}

/**
 * update a webhook
 *
 * @param {object} { id, ...data }
 */
function* updateWebhookSaga({ payload: { id, ...data } }) {
  try {
    yield put(updateWebhookInit());
    let endpoint = buildApiRoute(apiEndpoints.updateWebhook, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateWebhookSuccess(response.data));
  } catch (err) {
    yield put(updateWebhookFailure(err));
    Notify.error('Webhook update failed');
  }
}

/**
 * get a single webhook
 */
function* getWebhookSaga({ payload: webhookId }) {
  try {
    yield put(getWebhookInit());
    let endpoint = buildApiRoute(apiEndpoints.getWebhook, webhookId);
    let response = yield callWrapperSaga(() => http.get(endpoint, webhookId));
    yield put(getWebhookSuccess(response.data));
  } catch (err) {
    yield put(getWebhookFailure(err));
  }
}

/**
 * get a webhooks list
 *
 */
function* getWebhooksSaga({ payload }) {
  yield put(getWebhooksInit());
  try {
    let endpoint = `${apiEndpoints.getWebhooks}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getWebhooksSuccess(response.data));
  } catch (err) {
    yield put(getWebhooksFailure(err));
  }
}

/**
 * initiate edit webhook
 * */
function* editWebhookSaga({ payload: webhookId }) {
  try {
    yield put(editWebhookInit());
    let endpoint = buildApiRoute(apiEndpoints.getWebhook, webhookId);
    let response = yield callWrapperSaga(() => http.get(endpoint, webhookId));
    yield put(editWebhookSuccess(response.data));
  } catch (err) {
    yield put(editWebhookFailure(err));
  }
}

function* deleteWebhookSaga({ payload: webhookId }) {
  try {
    yield put(deleteWebhookInit(webhookId));
    let endpoint = buildApiRoute(apiEndpoints.deleteWebhook, webhookId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Webhook deleted successfully.');
    yield put(deleteWebhookSuccess(webhookId));
  } catch (err) {
    Notify.error('Failed to delete webhook');
    yield put(deleteWebhookFailure(err));
  }
}

function* webhookSaga() {
  yield takeLatest(createWebhook, createWebhookSaga);
  yield takeLatest(updateWebhook, updateWebhookSaga);
  yield takeLatest(getWebhook, getWebhookSaga);
  yield takeLatest(getWebhooks, getWebhooksSaga);
  yield takeLatest(editWebhook, editWebhookSaga);
  yield takeLatest(deleteWebhook, deleteWebhookSaga);
}

export default webhookSaga;
export { createWebhook, updateWebhook, getWebhook, getWebhooks, editWebhook, deleteWebhook };
