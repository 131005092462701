import * as Yup from 'yup';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SubmitButton from 'core/buttons/SubmitButton';
import AmountField from 'core/forms/AmountField';
import Clipboard from 'core/clipboard/Clipboard';

import { zaiRefundPayment } from 'app/features/transactions/zaiTransactionSaga';
import { resetRefundPayment } from 'app/features/transactions/zaiTransactionSlice';

const schema = Yup.object().shape({
  webhookId: Yup.string().required('Webhook ID is required'),
  amount: Yup.number().required('Amount is required').typeError('Enter a refund amount'),
  remarks: Yup.string().required('Remarks is required').typeError('Enter a remarks'),
});

export default function RefundPaymentTableRowForm({ row, onRefundSuccess }) {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.zaiTransactions.refundPayment);

  const { isLoading, webhookId, isSuccess } = state;

  const [formData, setFormData] = useState({
    webhookId: row.webhook_id,
    amount: null,
    remarks: null,
  });

  const [errors, setErrors] = useState({
    amount: '',
    remarks: '',
  });

  const clearErrors = (name) => {
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleRefundPayment = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      dispatch(zaiRefundPayment(formData));
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        if (error.path !== undefined) {
          validationErrors[error.path] = error.errors[0];
        }
      });
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetRefundPayment());
      onRefundSuccess?.();
    }
  }, [isSuccess]);

  return (
    <TableRow>
      <TableCell>{row.sn}</TableCell>
      <TableCell>
        <Clipboard content={row.webhook_id} />
      </TableCell>
      <TableCell align="right">{row.transaction_amount.toLocaleString()}</TableCell>
      <TableCell>{row.transaction_currency}</TableCell>
      <TableCell>
        <AmountField
          type="text"
          name="amount"
          size="small"
          placeholder="Enter amount"
          value={formData.amount ?? ''}
          onChange={(e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
          }}
          helperText={errors?.amount}
          error={!!errors?.amount}
          onFocus={() => clearErrors('amount')}
        />
      </TableCell>
      <TableCell>
        <TextField
          name="remarks"
          size="small"
          placeholder="Enter remarks"
          value={formData.remarks ?? ''}
          onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
          error={!!errors?.remarks}
          helperText={errors?.remarks}
          onFocus={() => clearErrors('remarks')}
        />
      </TableCell>
      <TableCell>
        <SubmitButton
          size="small"
          onClick={handleRefundPayment}
          disabled={isLoading}
          {...(webhookId === row.webhook_id
            ? {
                isLoading,
              }
            : undefined)}
        >
          Refund
        </SubmitButton>
      </TableCell>
    </TableRow>
  );
}

RefundPaymentTableRowForm.propTypes = {
  row: PropTypes.any.isRequired,
  onRefundSuccess: PropTypes.func.isRequired,
};
