import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import FundingSourceForm from 'components/funding-sources/FundingSourceForm';
import FundingSourceProgressIndicatorModal from 'components/funding-sources/FundingSourceProgressIndicatorModal';

function AddFundingSource() {
  const navigate = useNavigate();

  return (
    <PageContent
      title="Add Funding Source"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('funding-sources.index'))}>List Funding Source</Button>
      }
    >
      <FundingSourceForm isAddMode={true} />
      <FundingSourceProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddFundingSource);
