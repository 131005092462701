import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put, call } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getZaiTransactionsInit,
  getZaiTransactionsSuccess,
  getZaiTransactionsFailure,
  checkBalanceSuccess,
  checkBalanceFailure,
  checkBalanceInit,
  makePaymentInit,
  makePaymentSuccess,
  makePaymentFailure,
  getZaiWebhookLogsInit,
  getZaiWebhookLogsFailure,
  getZaiWebhookLogsSuccess,
  refundPaymentInit,
  refundPaymentSuccess,
  refundPaymentFailure,
  getTransactionInit,
  getTransactionSuccess,
  getTransactionFailure,
  getZaiRefundLogsInit,
  getZaiRefundLogsSuccess,
  getZaiRefundLogsFailure
} from './zaiTransactionSlice';
import Notify from 'utils/Notify';

const getZaiTransactions = createAction('zai-transactions/list');
const getZaiTransaction = createAction('zai-transactions/get');
const zaiCheckBalance = createAction('zai-transactions/check-customer-balance');
const zaiMakePayment = createAction('zai-transactions/make-payment');
const getZaiWebhookLogs = createAction('zai-transactions/webhook-logs');
const getZaiRefundLogs = createAction('zai-transactions/refund-logs');
const zaiRefundPayment = createAction('zai-transactions/refund-payment');

/**
 * get a transactions list
 *
 */
function* getZaiTransactionsSaga({ payload }) {
  yield put(getZaiTransactionsInit());
  try {
    let endpoint = `${apiEndpoints.getZaiTransactions}?${qs.stringify(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getZaiTransactionsSuccess(response.data));
  } catch (err) {
    yield put(getZaiTransactionsFailure(err));
  }
}

/**
 * get a single transaction
 */
function* getZaiTransactionSaga({ payload: transactionId }) {
  try {
    yield put(getTransactionInit());
    let endpoint = buildApiRoute(apiEndpoints.getTransaction, transactionId);
    let response = yield callWrapperSaga(() => http.get(endpoint, transactionId));
    yield put(getTransactionSuccess(response.data));
  } catch (err) {
    yield put(getTransactionFailure(err));
  }
}


/**
 * check balance
 */
function* zaiCheckBalanceSaga({ payload }) {
  yield put(checkBalanceInit());
  try {
    let endpoint = buildApiRoute(apiEndpoints.zaiCheckBalance, payload.customerId);
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(checkBalanceSuccess(response.data));
  } catch (err) {
    yield put(checkBalanceFailure(err));
  }
}

/**
 * make payment
 */
function* zaiMakePaymentSaga({ payload }) {
  yield put(makePaymentInit());
  try {
    let endpoint = apiEndpoints.zaiMakePayment;
    let response = yield callWrapperSaga(() => http.put(endpoint, payload));
    Notify.success('Paid successfully');
    yield put(makePaymentSuccess(response.data));
  } catch (err) {
    Notify.error(err?.response?.data?.message ?? 'Request Failed');
    yield put(makePaymentFailure(err));
  }
}

/**
 * get a webhook logs
 */
function* getZaiWebhookLogsSaga({ payload }) {
  yield put(getZaiWebhookLogsInit());
  try {
    let endpoint = `${apiEndpoints.getZaiWebhookLogs}?${qs.stringify(payload)}`;
    let response = yield call(() => http.get(endpoint));
    yield put(getZaiWebhookLogsSuccess(response.data));
  } catch (err) {
    yield put(getZaiWebhookLogsFailure(err));
  }
}

/**
 * get a refund logs
 */
function* getZaiRefundLogsSaga({ payload }) {
  yield put(getZaiRefundLogsInit());
  try {
    let endpoint = `${apiEndpoints.getZaiRefundLogs}?${qs.stringify(payload)}`;
    let response = yield call(() => http.get(endpoint));
    yield put(getZaiRefundLogsSuccess(response.data));
  } catch (err) {
    yield put(getZaiRefundLogsFailure(err));
  }
}

/**
 * refund payment
 */
function* zaiRefundPaymentSaga({ payload }) {
  yield put(refundPaymentInit(payload));
  try {
    let endpoint = apiEndpoints.zaiRefundPayment;
    let response = yield callWrapperSaga(() => http.put(endpoint, payload));
    Notify.success('Refunded successfully.');
    yield put(refundPaymentSuccess(response.data));
  } catch (err) {
    Notify.error(err?.response?.data?.message ?? 'Request Failed');
    yield put(refundPaymentFailure(err));
  }
}

function* zaiTransactionSaga() {
  yield takeLatest(getZaiTransaction, getZaiTransactionSaga);
  yield takeLatest(getZaiTransactions, getZaiTransactionsSaga);
  yield takeLatest(zaiCheckBalance, zaiCheckBalanceSaga);
  yield takeLatest(zaiMakePayment, zaiMakePaymentSaga);
  yield takeLatest(getZaiWebhookLogs, getZaiWebhookLogsSaga);
  yield takeLatest(getZaiRefundLogs, getZaiRefundLogsSaga);
  yield takeLatest(zaiRefundPayment, zaiRefundPaymentSaga);
}

export default zaiTransactionSaga;

export {
  getZaiTransactions,
  getZaiTransaction,
  zaiCheckBalance,
  zaiMakePayment,
  getZaiWebhookLogs,
  getZaiRefundLogs,
  zaiRefundPayment,
};
