import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';

import MoreActions from 'core/lab/MoreActions';
import ReloadButton from 'core/buttons/ReloadButton';
import ColumnVisibility from 'core/table/ColumnVisibility';
import SelectPartner from 'components/partners/SelectPartner';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Auth from 'utils/Auth';
import Sheet from 'utils/Sheet';
import app from 'app/config/app';
import useQueryParams from 'hooks/useQueryParams';
import { getPartnerFundingSources } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';
import { setParam, deleteFilterParams } from 'app/features/partner-funding-sources/partnerFundingSourceSlice';

export default function PartnerFundingSourcesActions({ table }) {
  const dispatch = useDispatch();

  const partnerFundingSourceState = useSelector((state) => state.partnerFundingSources.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  // let availableFilters = [
  //   {
  //     label: 'Name',
  //     value: 'name',
  //   },
  //   {
  //     label: 'ID Number',
  //     value: 'id_number',
  //   },
  //   {
  //     label: 'Mobile Number',
  //     value: 'mobile_number',
  //   },
  //   {
  //     label: 'Email',
  //     value: 'email',
  //   },
  //   {
  //     label: 'Date of Birth',
  //     value: 'date_of_birth',
  //   },
  // ];

  const { params: appliedFilters } = partnerFundingSourceState;

  // const [search, setSearch] = useState(appliedFilters?.search ?? '');

  // const handleOnFilter = useCallback((data) => {
  //   dispatch(setParam({ ...data, page_number: 1 }));
  // }, []);

  // const handleOnSearch = useCallback((e) => {
  //   setSearch(e.target.value);
  //   debounce(() => {
  //     dispatch(setParam({ search: e.target.value, page_number: 1 }));
  //   }, 500);
  // }, []);

  // const handleOnClearSearch = useCallback(() => {
  //   setSearch('');
  //   dispatch(deleteFilterParams('search'));
  // }, []);

  // const handleOnReset = useCallback((e) => {
  //   dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  // });

  useEffect(() => {
    updateQueryParams(appliedFilters);
    // setSearch(appliedFilters?.search ?? '');
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <TableActionsContainer>
      <Box>
        {/* <SearchBox
          onChange={handleOnSearch}
          onClickClearSearch={handleOnClearSearch}
          value={search || ''}
        /> */}
      </Box>
      <Box display="flex" gap={1}>
        {Auth.isSuperAdmin() && (
          <SelectPartner
            id="select-partner"
            onSelected={(value) => {
              dispatch(setParam({ partner_id: value }));
            }}
            sx={{ width: '300px' }}
          />
        )}

        {/* <FilterPopup
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={(data) => handleOnFilter(data)}
          onReset={handleOnReset}
        /> */}

        {table && <ColumnVisibility table={table} />}
        <ReloadButton
          onClick={() => {
            dispatch(
              getPartnerFundingSources({
                ...appliedFilters,
                page_number: 1,
                partnerId: partnerFundingSourceState.partnerId,
              }),
            );
          }}
        />
        <MoreActions
          onExportToCsv={() => Sheet.exportReactTableSelectedRowsToCsv(table, 'partner_funding_sources_')}
          onExportToExcel={() => Sheet.exportReactTableSelectedRowsToExcel(table, 'partner_funding_sources_')}
        />
      </Box>
    </TableActionsContainer>
  );
}

PartnerFundingSourcesActions.propTypes = {
  table: PropTypes.object,
};
