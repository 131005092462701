import { useRef, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import FormContainer from 'core/forms/FormContainer';
import FormTextField from 'hook-form-controls/FormTextField';
import ValidationErrorMessages from 'core/errors/ValidationErrorMessages';

import app from 'app/config/app';
import { createApiConfig, updateApiConfig } from 'app/features/api-configs/apiConfigSaga';

const apiConfigSchema = Yup.object().shape({
  api_partner_code: Yup.string().required('API Partner Code is required'),
  api_url: Yup.string().url('API URL must be a valid URL').required('API URL is required'),
  api_user_id: Yup.string().required('User ID is required'),
  api_user_secret: Yup.string().nullable(),
  ref1: Yup.string().nullable().optional(),
  ref2: Yup.string().nullable().optional(),
  ref3: Yup.string().nullable().optional(),
  remarks: Yup.string().nullable().optional(),
});

function ApiConfigForm({ isAddMode, apiConfig, retries, isCreated }) {
  const submitButtonRef = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...(apiConfig
        ? {
            id: apiConfig?.config_id ?? '',
            api_partner_code: apiConfig?.api_partner_code ?? null,
            // api_url: apiConfig?.api_url ?? null,
            // api_user_id: apiConfig?.api_user_id ?? null,
            // api_user_secret: apiConfig?.api_user_secret ?? null,
            // ref1: apiConfig?.ref1 ?? null,
            // ref2: apiConfig?.ref2 ?? null,
            // ref3: apiConfig?.ref3 ?? null,
            remarks: apiConfig?.remarks ?? null,
          }
        : {}),
    },
    resolver: yupResolver(apiConfigSchema),
    mode: 'onChange',
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = methods;

  const onSubmit = async (data) => {
    dispatch(isAddMode ? createApiConfig(data) : updateApiConfig({ ...data, id: apiConfig.id }));
  };

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  useEffect(() => {
    if (isCreated) reset({ partner_id: getValues('partner_id') });
  }, [isCreated]);

  return (
    <FormContainer>
      {app.isDevelopmentMode() && <ValidationErrorMessages errors={errors} />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item sm={12} md={6}>
              <FormTextField name="api_partner_code" label="API Partner Code" />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="api_url" label="API URL" />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="api_user_id" label="API User ID" />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="api_user_secret" label="API User Secret" />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="ref1" label="Ref1" />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="ref2" label="Ref2" />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="ref3" label="Ref3" />
            </Grid>
            <Grid item sm={12}>
              <FormTextField name="remarks" label="Remarks" multiline />
            </Grid>
            <Grid item sm={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <TextButton type="button" color="error" onClick={() => navigate(-1)}>
                  Cancel
                </TextButton>
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAddMode ? 'Create API Config' : 'Update API Config'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(ApiConfigForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode ? state.apiConfigs.create.retries : state.apiConfigs.update.retries,
    isCreated: state.apiConfigs.create.isCreated,
  };
}

ApiConfigForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  apiConfig: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
};
