import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Transaction from 'components/transactions/Transaction';

import { buildRoute } from 'routes';
import Button from 'core/buttons/Button';
import { resetGetTransactionFailure } from 'app/features/transactions/transactionSlice';
import withRole from 'hoc/withRole';
import acl from 'app/config/acl';

function ViewTransaction({ isNetworkError }) {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.transactions.show);

  useEffect(() => {
    if (isError) dispatch(resetGetTransactionFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(resetGetTransactionFailure())}
      />
    );

  return (
    <PageContent
      title="Transaction Details"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('transactions.index'))}>All Transaction</Button>
      }
    >
      <Transaction transactionId={params.transactionId} />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER])
)(ViewTransaction);
