import * as PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import Clipboard from 'core/clipboard/Clipboard';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import DateUtils from 'utils/DateUtils';
import constants from 'app/config/constants';
import RefundLogPagination from './RefundLogPagination';
import { getZaiRefundLogs } from 'app/features/transactions/zaiTransactionSaga';

export default function RefundLogs({ customerId }) {
  const dispatch = useDispatch();

  const [columnVisibility, setColumnVisibility] = useState({});

  const transactionState = useSelector((state) => state.zaiTransactions.refundLogs);

  const { data, params, isLoading, isError, errorCode } = transactionState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Webhook ID',
        accessorKey: 'webhook_id',
        cell: ({ getValue }) => <Clipboard content={getValue()} />,
      },
      {
        header: 'Refund amount',
        accessorFn: (row) => row.refund_amount,
      },
      {
        header: 'Currency',
        accessorFn: (row) => row.transaction_currency,
      },
      {
        header: 'Remarks',
        accessorFn: (row) => row.remarks,
      },
      {
        header: 'Created At',
        accessorFn: (row) => DateUtils.isoToString(row.created_ts),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getZaiRefundLogs({
        ...params,
        CustomerId: customerId,
      }),
    );
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <RefundLogPagination />
    </TableContainer>
  );
}

RefundLogs.propTypes = {
  customerId: PropTypes.string,
};
